/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.SlickSliderBlock {
    .slick {
        &-slider {
            margin: 0 20px;

            @include mobile {
                margin: 0 5px;
            }
        }
    
        &-list {
            padding: 50px 0px 25px;
            margin: 0 5px;
        }
    
        &-slide {
            padding: 0 10px;
        }

        &-next,
        &-prev {
            inset-block-start: calc(50% + 0px);
            width: 30px;
            height: auto;
            
            &::before {
                @include mobile {
                    font-size: 24px;
                }

                background: none;
                color: var(--secondary-blue-color);
                font-size: 28px;
            }
        }

        &-next {
            @include mobile {
                inset-inline-end: -20px;
            }

            &::before, {
                content: "→";
            }
        }
        
        &-prev {
            @include mobile {
                inset-inline-start: -20px;
            }

            &::before {
                content: "←";
            }
        }

        &-dots {
            li {
                button {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}

