/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.CATEGORIES-BANNER-WRAPPER {
    margin-block-end: 15px;
    padding: 0;

    @include wide-desktop {
        text-align: center;
    }

    @include narrow-desktop {
        text-align: center;
    }

    @include ultra-wide-desktop {
        padding: 0 20px;
    }

    @include narrow-mobile {
        padding: 0 10px;
    }

    h2 {
        text-align: center;
        font-size: 24px;
        line-height: 26px;
        margin-block-end: 40px;
        margin-block-start: 0;
        
        @include mobile {
            text-transform: initial;
            margin-block-end: 30px;
        }

        @include desktop {
            text-transform: lowercase;
            font-size: 32px;
            line-height: 48px;

            &::first-letter {
                text-transform: capitalize
            }
        }
    }

    .pagebuilder-column-group {
        column-gap: 20px;
        margin-block-end: 20px;

        @include wide-desktop {
            /* stylelint-disable-next-line declaration-no-important */
            display: inline-flex!important;
        }

        @include narrow-desktop {
            /* stylelint-disable-next-line declaration-no-important */
            display: inline-flex!important;
        }

        @include narrow-mobile {
            flex-direction: column;
            row-gap: 20px;
        }

        @include narrow-tablet {
            /* stylelint-disable-next-line declaration-no-important */
            display: grid !important;
            grid-template-columns: repeat(4, 1fr);
            row-gap: 20px;

            .pagebuilder-column {
                &.CENTERED {
                    grid-template-columns: repeat(1, 1fr);
                    position: relative;
                    inset-inline-start: 50%;
                }
            }
        }

        @include desktop {
            .pagebuilder-column {
                padding-inline: 10px;
                padding-block-start: 10px;
                padding-block-end: 10px;
            }
        }

        // [MVP-361] - DISOLAY 5 CATEGORIES in one row for TABLET
        // RISKS - Unmaintainable, can not be added more then 5 products.
        // @include narrow-tablet {
        //     &:first-of-type {
        //         position: absolute;
        //     }

        //     &:nth-of-type(2) {
        //         transform: translateX(150px);
        //         max-width: 500px;
        //     }

        //     position: relative;
        //     /* stylelint-disable-next-line declaration-no-important */
        //     display: grid !important;
        //     grid-template-columns: repeat(5, 1fr);
        //     row-gap: 20px;
        // }

        @include narrow-mobile {
            flex-direction: initial;
            column-gap: 10px;
            margin-block-end: 0px;
            /* stylelint-disable-next-line declaration-no-important */
            display: block!important;

            .pagebuilder-column {
                width: 33.33%;
                display: INLINE-BLOCK;
                padding: 5px 10px 10px;
            }
        }
    }

    .pagebuilder-column {
        // [MVP-361] - DISOLAY 5 CATEGORIES in one row for TABLET
        // RISKS - Unmaintainable, can not be added more then 5 products.
        // @include narrow-tablet {
        //     width: 128px;
        //     height: 100px;
        // }

        img {
            border-radius: 50%;

            @include narrow-desktop {
                width: 200px;
                height: 200px;
            }

            @include wide-desktop {
                height: 200px;
                width: 200px;
            }

            &:hover {
                transition: 0.5s;
                box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .1),
                    0 6px 16px rgba(0, 0, 0, .1),
                    0 9px 28px 8px rgba(0, 0, 0, .1);
            }
        }
    }
}
