/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ResetAttributes {
    margin: 0;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-block-end: 30px;

    &-Title {
        text-align: start;
        font-weight: 500;
        /* stylelint-disable-next-line declaration-no-important */
        padding: 0 !important;
        height: 22px;
        color: #6C6F7A;
        font-size: 16px;
        line-height: 24px;
    }

    &-MobileTitle {
        text-align: start;
        font-weight: 500;
        color: #6C6F7A;
        font-size: 14px;
        line-height: 22px;
        /* stylelint-disable-next-line declaration-no-important */
        padding: 0 0 12px 0 !important;
        height: auto;
    }

    &-AttributeValue {
        min-width: 130px;
        margin: 0;
        padding: 0 10px;
        box-sizing: border-box;
        border: 1px solid #E2E4E7;
        border-radius: 18px;
        background-color: #FFFFFF;
        gap: 7px;
        flex-direction: row-reverse;

        @include mobile {
            height: 30px;
        }

        @include desktop {
            height: 36px;
        }
    }

    &-AttributeLabel {
        color: var(--primary-blue-color);
        font-family: 'Roboto-Regular', sans-serif;
        font-size: 14px;
        line-height: 24px;
        font-weight: normal;
    }

    &-AttributeOption {
        color: #6C6F7A;
        font-family: 'Roboto-Regular', sans-serif;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 24px;
    }

    &-CloseIcon {
        height: 20px;
        line-height: 20px;
        display: flex;

        &:hover {
            cursor: pointer;
        }

        .CloseIcon {
            fill: #6C6F7A;
            height: 21px
        }
    }
}
