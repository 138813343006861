.MOTIVATIONS-SECTION {
    margin-block-start: 10px;

    picture {
        text-align: center;

        img {
            width: 70px;
            height: 70px;
        }
    }

    h2,
    h3,
    p {
        font-size: 12px;
        line-height: 12px;
        text-align: center;

        @include desktop {
            font-size: 16px;
        }
    }

    h2,
    h3 {
        color: var(--primary-blue-color);
        font-weight: 500;

        @include mobile {
            margin: 10px 0 5px;
        }


        @include desktop {
            line-height: 24px;
        }
    }

    p {
        color: #6C6F7A;

        @include desktop {
            line-height: 22px;
        }
    }

    .pagebuilder-column-group {
        gap: 15px;
    }
}
