/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.ContactPage {
    &-Heading {
        font-size: 36px;
        color: var(--primary-blue-color);

        @include mobile {
            font-size: 24px;
        }
    }

    &-ColumnWrapper {
        display: block;

        @include desktop {
            margin-block-end: 80px;
        }
    }

    &-Column {
        margin-block-end: 40px;

        &_isContent {
            margin-inline-end: 0;
        }
    }

    &-ContactsHeader {
        font-size: 28px;
        text-transform: unset;

        @include mobile {
            font-size: 24px;
        }
    }

    &-Address {
        color: #6c6f7a;
    }

    &-AddressDetails {
        font-size: 16px;
        font-style: normal;
        line-height: 22px;
    }

    &-ContactSource {
        margin-block-start: 20px;
        display: flex;
        align-items: center;
        color: #6c6f7a;

        &,
        a {
            color: inherit;
            font-size: 16px;
            font-weight: 400;

            @include mobile {
                font-size: 14px;
            }
        }

        svg {
            margin-inline-end: 10px;
        }
    }

    &-divider {
        border-top: 1px solid #e2e4e7;
        margin: 40px 0;
    }
}
