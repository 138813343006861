/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.BitCashCharge {
    &-Heading {
        font-size: 20px;
    }

    &-Button {
        width: 100%;
        color: var(--secondary-success-color);
        background-color: #027ed7;
        margin-block-start: 20px;
    }
}

.MyBitcash {
    &-CustomerInformation {
        padding-block-end: 40px;
        margin-block-end: 40px;
        border-bottom: 1px solid var(--primary-grey-color);

        .Message {
            font-size: 14px;
        }
    }

    &-CustomerInformationHeading {
        font-size: 20px;
        margin-block-end: 20px;
    }

    &-CustomerInfoFields {
        font-size: 16px;
        line-height: 30px;
        padding: 30px;
        background-color: #f6fafc;
        border-radius: 10px;
    }

    &-CustomerInfoFieldName {
        color: var(--primary-blue-color);
        font-weight: bold;
        margin-inline-end: 5px;
    }

    &-CustomerInfoFieldValue {
        color: #6c6f7a;
    }

    &-SuccessTitleContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin-block-end: 30px;

        h2 {
            font-size: 28px;
        }

        svg {
            width: 32px;
            height: 32px;
        }

        @include mobile {
            h2 {
                font-size: 22px;
            }
    
            svg {
                width: 26px;
                height: 26px;
            }
        }
    }

    &-HomeButton {
        width: 100%;
    }

    .apple-pay-button {
        width: 100%;
        height: 50px;
        -webkit-appearance: -apple-pay-button;
    }
}
