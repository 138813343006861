div[data-content-type*="divider"] {
    hr {
        height: 1px;
        background-color: #E2E4E7;
        margin: 30px 20px;

        @include desktop {
            margin: 30px 0;
        }
    }
}

ul {
    li {
        &::before {
            font-size: 28px;
            line-height: 24px;
            color: var(--secondary-blue-color);
        }
    }
}
