/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.NoMatch {
    min-height: calc(100vh - var(--breadcrumbs-height) - 550px);

    h1 {
        font-size: 44px;
        color: var(--primary-blue-color);
        font-weight: bold;
        margin: 30px 0;
    }

    &-Subtitle {
        font-size: 20px;
        color: var(--primary-blue-color);
        font-weight: bold;
        margin: 30px 0 10px;
    }

    &-Description {
        font-size: 14px;
        color: #6c6f7a;
        line-height: 24px;
    }

    &-Button {
        padding: 15px 60px;

        @include desktop {
            &:not([disabled]):hover {
                padding: 15px 60px;
            }
        }
    }

    &-noMobile {
        @include mobile {
            display: none;
        }
    }

    @include desktop {
        padding-block-end: 60px;
    }
}
