/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.CarouselScrollItem {
    width: 100%;
    min-height: 120px;
    max-height: 120px;
    border: none;

    &,
    & + .CarouselScrollItem {
        margin-block-end: var(--carousel-scroll-gap);
        margin-inline-start: 0;
    }
}
