/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.ForgotPassword {
    .ContentWrapper {
        min-height: calc(100vh - var(--header-total-height));
        padding-block-start: 28px;
        padding-block-end: 50px;
        padding-inline: 20px;

        @include desktop() {
            padding-block-start: 70px;
        }
    }


    &-Title {
        margin-block: 15px 6px;
        text-transform: unset;

        @include desktop() {
            text-align: center;
        }
    }

    .MyAccountOverlay-Description {
        text-align: center;
    }

    &-CreateAccountButton,
    &-SignInButton {
        width: 100%;
    }

    &-Input {
        &_type_email {
            width: 100%;
        }
    }

    &-InnerWrapper {
        @include desktop() {
            margin: 0 auto;
            width: 50%;
            max-width: 450px;
        }

        .MyAccountOverlay {
            &-Additional {
                display: none;
            }
        }

        button {
            @include desktop {
                margin-block-start: 20px;
            }
        }

        button,
        input {
            min-width: 250px;

            @include mobile {
                width: 100%;
            }
        }
    }

    &-SignInWrapper {
        margin-block-start: 100px;
    }

    .Loader {
        margin: 0;
    }
}
