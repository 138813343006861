/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Notification {
    padding: 15px 40px;

    &-Button {
        display: none;
    }

    &_type {
        &_error {
            --notification-color: var(--warning-color);
            --notification-background: var(--warning-background);
            --notification-height: var(--notification-error-height);
        }

        &_success {
            --notification-color: var(--success-color);
            --notification-background: var(--success-background);
            --notification-height: var(--notification-success-height);
        }
    }
}
