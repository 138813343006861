/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/parts';

.ArrowIcon {
    fill: var(--color-black);
    cursor: pointer;

    &_direction {
        &_right {
            transform: rotate(180deg);

            [dir="rtl"] & {
                transform: none;
            }
        }

        &_top {
            transform: rotate(90deg);
        }

        &_bottom {
            transform: rotate(-90deg);
        }
    }
}
