/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

:root {
    --message-warning-color: var(--warning-color);
    --message-warning-background-color: var(--warning-background);
    --message-success-color: var(--success-color);
    --message-success-background-color: var(--success-background);
}

.Message {
    padding: 20px 26px;
    border-radius: 10px;
    margin: 20px 0;
    font-size: 16px;
    line-height: 24px;

    &_success {
        color: var(--message-success-color);
        background-color: var(--message-success-background-color);
    }

    &_warning {
        color: var(--message-warning-color);
        background-color: var(--message-warning-background-color);
    }
}

