/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.SearchField {
    &-Wrapper {
        @include mobile {
            padding-block-end: calc(var(--footer-nav-height) + 28px);
        }

        @include desktop {
            &::before {
                content: "";
                position: absolute;
                top: -9px;
                right: 120px;
                z-index: 100;
                height: 15px;
                width: 15px;
                transform: rotate(45deg);
                background-color: var(--overlay-background);
                border-top: var(--my-account-overlay-border-width) solid var(--overlay-desktop-border-color);
                border-left: var(--my-account-overlay-border-width) solid var(--overlay-desktop-border-color);
            }
        }
    }

    &-CloseIcon {
        inset-block-start: 12px;
    }
}
