.HERO-BANNER-SLIDER-WRAPPER {
    @include ultra-wide-desktop {
        padding: 0;
    }

    .SliderWidget {
        &-Figure {
            @include desktop {
                .Image {
                    height: 400px;
    
                    img {
                        height: 400px;
                    }
                }
            }
        }
    }

    .SliderWidget-FigureImage {
        padding-block-end: 0;
    }
}
