/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.NotificationList {
    .Notification {
        @include desktop {
            border-radius: 10px;
        }

        &-Text {
            font-family: 'Roboto-Regular', sans-serif;
            font-size: 16px;
            line-height: 24px;
            font-weight: normal;
        }
    }

    @include mobile {
        inset-block-start: calc(70px + env(safe-area-inset-top));
    }
}
