/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

/* stylelint-disable declaration-no-important */

.CmsPage {
    @include mobile {
        margin-block-end: 85px;
        margin-block-start: 0;
    }

    &-Wrapper {
        padding-inline: 0 !important;
        margin-block-end: 50px;

        @include mobile {
            padding-block-start: env(safe-area-inset-top);
        }

        @include desktop {
            margin-block-end: 80px;
        }

        margin-block-start: 0;

        .PAGE-TITLE {
            color: var(--primary-blue-color);
            font-size: 36px;
            font-weight: bold;
            line-height: 39px;
            padding-block-end: 30px;
            margin: 0;
            text-transform: unset;

            @include mobile {
                position: fixed;
                inset-block-start: calc(18px + env(safe-area-inset-top)) !important;
                inset-inline-start: 50%;
                transform: translateX(-50%);
                font-size: 16px;
                line-height: 17px;
                text-align: center;
                z-index: 100;
                min-width: 250px;
                text-transform: uppercase;
                height: 34px;
                display: flex;
                align-items: center;
            }

            @include ultra-wide-desktop {
                padding: 0 20px;
            }
        }

        .SECTION {
            margin-block-end: 40px;

            @include mobile {
                margin-block-end: 20px;
            }

            @include ultra-wide-desktop {
                padding: 20px 20px 0px;
            }

            h2, h3 {
                padding-block-end: 16px;
                color: var(--primary-blue-color);
                font-size: 20px;
                font-weight: bold;
                line-height: 22px;
                margin: 0;
                text-transform: unset;
    
                @include desktop {
                    padding-block-end: 30px;
                }
            }
    
            p,
            li {
                font-size: 14px;
                line-height: 22px;
                color: #6C6F7A;
    
                @include desktop {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
                    
            p {
                margin-block-end: 20px;
            }
        }
    }

    .Slider-Crumbs {
        margin-block-end: 0;
    }

    .widget {
        overflow-x: unset;
    }
}

.CmsPage-Wrapper_type_win-prizes {
    overflow: hidden;
}

.popupImage {
    width: auto;
    height: 648px;

    @include mobile {
        height: 500px;
    }

    @include mobile-height-small {
        height: 500px;
    }
}

.popupCloseButton {
    position: absolute;
    z-index: 999;
    font-size: 20px;
    background: #fff;
    border: 0px;
    padding: 5px 10px 5px 10px;
}

.win-prize-nomargin {
    margin: 0px!important;
}

.CmsPage-Wrapper_type_win-prize {
    margin-block-end: 0px!important;
}

.downloadButton {
    background: #000;
    margin-block-start: -3px;
    // padding: 0 60px;
    position: fixed;
    inset-block-end: 0px;

    @include mini-mobile {
        // padding: 0 100px;
    }

    @include mini-mobile-2 {
        // padding: 0 150px;
    }
}

.bg-salmon {
    overflow: hidden;
}

.video-height-adjust {
    width: 100%;
    height: 85vh;
}

.bg-salmon .Header-Wrapper {
    position: absolute;
    width: 100%;
}

.bg-salmon .Header {
    background: transparent;
    box-shadow: 0 0 0 0;
}

.bg-salmon .Header-Button_type_back {
    background: transparent;
}

.ReactModalPortal {
    z-index: 9999;
}

.bg-salmon .NavigationTabs {
    display: none;
}
