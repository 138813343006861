/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.CarouselScroll {
    width: unset;
    margin: 0;
    align-items: baseline;
    display: flex;
    flex-direction: column;

    &-Content {
        flex-direction: column;
        width: 100%;
        transform: translateY(var(--translateY));
    }

    &-ContentWrapper {
        width: 100%;
        max-height: 550px;
    }
}
