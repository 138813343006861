/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

/* stylelint-disable declaration-no-important */

.INTRO-SECTION_ABOUT {
    margin-block-end: 30px;

    @include mobile {
        padding: 0 !important;
    }

    @include desktop {
        margin-block-end: 40px;
    }

    @include ultra-wide-desktop {
        padding: 0 20px;
    }

    .TITLE {
        color: var(--primary-blue-color);
        font-size: 36px;
        font-weight: bold;
        line-height: 39px;
        padding-block-end: 30px;

        @include mobile {
            text-transform: uppercase;
            font-size: 16px;
            position: fixed;
            inset-inline-start: 50%;
            inset-block-start: calc(15px + env(safe-area-inset-top));
            transform: translateX(-50%);
            z-index: 100;
        }
    }

    .masafi-video {
        video {
            width: 100%;
        }
    }
}

.ABOUT-SECTION_ABOUT {
    margin-block-end: 50px;

    @include desktop {
        margin-block-end: 80px;
    }

    .TITLE {
        font-weight: bold;
        text-transform: unset;
        text-align: start;
        font-size: 24px;
        line-height: 26px;
        margin-block-end: 15px;

        @include desktop {
            font-size: 28px;
            line-height: 30px;
            margin-block-end: 22px;
        }
    }

    .DESCRIPTION,
    .DESCRIPTION * {
        font-size: 14px;
        line-height: 22px;
        color: #6C6F7A;

        @include desktop {
            font-size: 16px;
            line-height: 24px;
        }
    }

    @include ultra-wide-desktop {
        padding: 0 20px;
    }
}

.USP-BANNER-WRAPPER_ABOUT {
    margin-block-end: 80px;

    @include desktop {
        margin-block-end: 40px;  
    }

    @include ultra-wide-desktop {
        padding: 0 20px;
    }

    .USP-BANNER-TITLE {
        text-transform: unset;
        font-size: 24px;
        line-height: 26px;

        @include desktop {
            font-size: 28px;
            line-height: 30px;
        }
    }
}
