/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Overlay {
    pointer-events: none;
    will-change: opacity;
    z-index: 10;
    opacity: 0;
    background-color: var(--overlay-content-background);

    &:not(.Overlay_isStatic) {
        @include mobile {
            -webkit-overflow-scrolling: touch;
            position: fixed;
            inset-block-start: var(--header-total-height);
            inset-inline-start: 0;
            width: 100%;
            height: calc(100% - var(--header-total-height));
            padding-block-end: var(--navigation-tabs-height);
            overflow-x: hidden;
            max-width: 100%;
        }
    }

    @include desktop {
        position: absolute;
        transition: opacity 200ms;
        height: 0;
        overflow: hidden;
    }

    &_isVisible {
        pointer-events: all;
        touch-action: pan-y;
        opacity: 1;
        z-index: 99;

        @include desktop {
            height: auto;
            overflow: visible;
        }
    }

    > * {
        background-color: inherit;
    }
}

.Overlay-Backdrop {
    &_isVisible {
        position: fixed;
        inset: 0;
        z-index: 1000;
        background-color: #ffffffe6;
    }
}
