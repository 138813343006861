/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable declaration-no-important */

.SearchPage {
    &-ProductListWrapper {
        .ProductCard {
            &-ActionsWrapper {
                display: none;
            }

            @include mobile {
                min-height: unset;
            }

            @include desktop {
                min-height: unset;
            }
        }
    }
}
