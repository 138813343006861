/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */


.MySubscription {
    &-popupCloseButton {
        z-index: 999;
        color: black;
        float: inline-end;
        font-size: 12px;
        cursor: pointer;
    }   

    &-modalDetailsWrapper {
        padding: 20px 10px;

        h3 {
            margin-block-start: 0px;
            margin-block-end: 0px;
        }

        table, tr, th, td {
            border: 1px solid rgb(52, 49, 49);
        }

        ul {
            li {
                &::before {
                    display: none;
                }
            }
        }
    }
}
