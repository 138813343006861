/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.SearchOverlay {
    &-Results {
        border-radius: var(--input-border-radius);
    }
    
    &-noResults {
        @include mobile {
            margin-block-start: 16px;
        }
    }
}

.SearchItem {
    &-Wrapper {
        align-items: center;
    }
}
