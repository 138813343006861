/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.Slider {
    --translateX: 0;
    --translateY: 0;
    --animation-speed: 0;
    --slider-height: 600px;
    --slide-height: 0;
    --slide-width: 0;
    --height-transition-speed: 0;

    max-width: 100%;
    overflow: hidden;
    touch-action: pan-y;
    transition: height var(--height-transition-speed);

    &-Wrapper {
        display: flex;
        align-items: flex-start;
        height: 100%;
        will-change: transform;
        transition: transform var(--animation-speed);
        transform: translate3d(var(--translateX), var(--translateY), 0);

        > * {
            flex-grow: 1;
            flex-shrink: 0;
            width: 100%;
            height: 100%;
        }

        &_isVertical {
            flex-direction: column;
        }
    }

    &-Image {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-Crumbs {
        height: auto;
        min-height: 16px;
        position: absolute;
        margin: auto;
        display: grid;
        grid-gap: 4px;
        width: 100%;
        grid-template-columns: repeat(auto-fit, 16px);
        justify-content: center;
        inset-block-end: 12px;

        @include desktop {
            grid-gap: 16px;
        }
    }

    &-Crumb {
        height: 6px;
        width: 16px;
        border-radius: 0%;
        background-color: #D8DEF5;
        will-change: width, height;
        transition: width 300ms, height 300ms;

        &_isActive {
            background-color: var(--primary-blue-color);
        }
    }

    &-Arrow {
        cursor: pointer;
        position: absolute;
        inset-block-start: calc(50% - 0px);
        width: 35px;
        height: 35px;

        .ChevronIcon {
            width: 100%;
            height: 100%;
            fill: #D8D8D8;
        }

        &_isPrev {
            inset-inline-start: 10px;

            &:dir(ltr) {
                transform: rotate(180deg) translateY(50%) scale(-1);
            }

            &:dir(rtl) {
                transform: translateY(50%);
            }
        }

        &_isNext {
            inset-inline-end: 10px;

            &:dir(ltr) {
                transform: translateY(-50%);
            }

            &:dir(rtl) {
                transform: rotate(180deg) translateY(-50%) scale(-1);
            }
        }

        &_isDisabled {
            opacity: .5;
        }
    }

    &Widget-Figure {
        @include mobile {
            height: 200px;
        }

        .Image {
            border-radius: 0px;

            @include mobile {
                border-radius: 0px;
                padding-block-end: 0;
            }
        }
    }
}
