/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@mixin expanded-content {
    opacity: 1;
    max-height: 100%;
}

.ExpandableContent {
    cursor: pointer;
    padding-block: 12px 0;

    &-Heading {
        color: var(--primary-blue-color);
        font-family: 'Roboto-Bold', sans-serif;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
    }
    

    &-Content {
        // Disabled as it prevents z-index use inside
        // will-change: max-height, padding, opacity;
        transition-property: opacity;
        transition-duration: 200ms;
        max-height: 0;
        overflow: hidden;
        opacity: 0;

        &_isContentExpanded {
            @include expanded-content;
        }
        
        @include desktop {
            margin-block-start: 0px;
        }
    }

    &-Button {
        &_isContentExpanded {
            padding-block-end: 0;
        }
    }
}
