/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.ContactForm {
    &-Header {
        h3 {
            font-size: 28px;
            text-transform: unset;
            color: var(--primary-blue-color);

            @include mobile {
                font-size: 24px;
            }
        }
    }

    &-Description {
        font-size: 16px;
        line-height: 24px;
        color: #6c6f7a;

        @include mobile {
            font-size: 14px;
        }
    }

    &-Field {
        margin-block-start: 24px;

        input,
        textarea {
            width: 100%;
        }

        textarea {
            min-height: 120px;
        }

        label {
            font-size: 16px;
            font-weight: 500;
            
            @include mobile {
                font-size: 14px;
            }
        }
    }

    button {
        margin-block-start: 24px;
        width: 100%;
    }

    form {
        margin: 0 auto;

        @include desktop {
            max-width: 650px;
        }
    }
}
