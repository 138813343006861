/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
/* stylelint-disable declaration-no-important */

.SearchMenuOverlay {
    overflow: inherit !important;
    position: fixed !important;
    width: 100%;
    height: 85% !important;
    background: white;
    inset-block-start: 15% !important;
    z-index: 1;
    inset-block-end: 0;
    text-align: center;
    font-size: 24px;
    animation: scaleDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    border-radius: 30px 30px 0 0;
    background-color: #ffffff;
    box-shadow: 0 -4px 10px 0 rgba(0, 0, 0, 0.15);

    &_isVisible {
        animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }

    &-CloseBtn {
        background: white;
        width: 60px;
        height: 60px;
        display: inline-block;
        border-radius: 50%;
        position: absolute;
        inset-block-start: -30px;
        inset-inline-start: 50%;
        transform: translateX(-50%);
        box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.2);
        z-index: 2;
    }

    &-Wrapper {
        margin-block-start: 50px;
        padding: 0 20px;
        overflow: auto;
        height: 100%;
    }
}
