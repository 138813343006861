.FAQ-PAGE {
    @include ultra-wide-desktop {
        padding: 0 20px;
    }

    h1 {
        color: var(--primary-blue-color);
        font-size: 36px;
        font-weight: bold;
        line-height: 39px;
        padding-block-end: 30px;

        @include mobile {
            text-transform: uppercase;
            font-size: 16px;
            position: fixed;
            inset-inline-start: 50%;
            inset-block-start: calc(15px + env(safe-area-inset-top));
            transform: translateX(-50%);
            z-index: 100;
        }
    }

    h2 {
        margin: 40px 0 20px;
        font-size: 20px;
        line-height: 22px;
        text-align: start;

        @include mobile {
            &:first-of-type {
                padding-block-start: 30px;
            }
        }

        @include desktop {
            margin: 40px 0 30px;
            font-size: 24px;
            line-height: 26px;
        }
    }

    .ExpandableContent {
        padding: 10px 0;
        cursor: auto;
        border-top: 1px solid var(--primary-grey-color);

        @include desktop {
            padding: 25px 0;
        }

        &:last-child {
            border-bottom: 1px solid var(--primary-grey-color);
        }

        &:hover {
            cursor: pointer;
        }

        &-Heading {
            font-size: 14px;
            line-height: 22px;

            @include desktop {
                font-size: 28px;
                line-height: 30px;
            }

            &:hover {
                cursor: pointer;
            }
        }
        
        &-Button_isContentExpanded {
            .ExpandableContent-Heading {
                padding-block-end: 30px;
            }
        }

        &-Content {
            p {
                color: #6C6F7A;
                font-size: 14px;
                line-height: 22px;

                @include desktop {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }

        svg {
            fill: var(--primary-blue-color); 
        }
    }
}
