/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.USP-BANNER-WRAPPER {
    background: linear-gradient(
        180deg,
        rgba(243, 251, 255, 0.05) 0%,
        #e7f6ff 47.35%,
        rgba(238, 246, 251, 0.05) 100%
    );
    font-family: "Roboto-Regular", sans-serif;

    @include ultra-wide-desktop {
        padding: 0 20px;
    }

    .USP-BANNER-TITLE {
        color: var(--primary-blue-color);
        font-weight: bold;
        line-height: 48px;
        text-align: center;
        text-transform: unset;
        font-size: 24px;
        margin-block-start: 0;
    }

    .USP-BANNER-HEADING {
        color: var(--primary-blue-color);
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 30px;
        text-align: center;
        padding-block-end: 15px;
        margin: 0 30px;
        font-family: inherit;
    }

    .USP-BANNER-DESCRIPTION {
        p {
            margin: 0 40px;
            color: #6c6f7a;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            font-family: inherit;
        }
    }

    .pagebuilder-column-group {
        justify-content: center;
        column-gap: 40px;

        .pagebuilder-column {
            background-color: white;
            max-width: 453px;
            margin: 10px 0;
            border-top: none;
            border-radius: 10px;

            &:hover {
                transition: 0.5s;
                box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.1),
                    0 6px 16px rgba(0, 0, 0, 0.1),
                    0 9px 28px 8px rgba(0, 0, 0, 0.1);
            }
        }
    }

    @include mobile {
        padding: 0 20px;

        .pagebuilder-column-group {
            flex-direction: column;
            align-items: center;

            .pagebuilder-column {
                min-height: 355px;
                border: 1px solid #167ebf1a;
                border-radius: 0 0 10px 10px;
            }
        }

        .USP-BANNER-TITLE {
            font-size: 24px;
        }

        .USP-BANNER-HEADING {
            font-size: 20px;
            padding-block-start: 30px;
        }

        .USP-BANNER-DESCRIPTION {
            p {
                font-size: 14px;
                padding-block-end: 25px;
            }
        }
    }

    @include desktop {
        .pagebuilder-column-group {
            .pagebuilder-column {
                min-height: 505px;
            }
        }

        .USP-BANNER-TITLE {
            font-size: 44px;
        }

        .USP-BANNER-HEADING {
            font-size: 28px;
            padding-block-start: 40px;
        }

        .USP-BANNER-DESCRIPTION {
            p {
                font-size: 20px;
            }
        }
    }
}
