/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.DatePicker {
    svg {
        position: absolute;
        pointer-events: none;
        right: 15px;
        bottom: 15px;
    }
}

.react-datepicker {
    font-family: "Roboto-Regular", sans-serif;
}

.react-datepicker__day,
.react-datepicker__day-name {
    color: #6c6f7a;
    font-size: 18px;
    padding: 6px;
    margin: 6px;
    height: 28px;
    width: 30px;

    @include desktop {
        font-size: 20px;
        padding: 6px;
        margin: 4px;
        height: 32px;
        width: 40px;
    }
}

.react-datepicker__day--disabled,
.disabled-date {
    color: #ccc;
    pointer-events: none;
}

.react-datepicker__day--selected {
    background-color: var(--primary-blue-color);
    color: #ffffff;
}

.react-datepicker__header {
    padding-block-start: 0;
}

.react-datepicker__current-month {
    color: #ffffff;
    background-color: #6c6f7a;
    padding: 10px 0;
}

.react-datepicker__navigation-icon {
    &::before {
        border-color: #ffffff;
        height: 6px;
        width: 6px;
        top: 9px;
    }
}

.react-datepicker__navigation:hover *::before {
    border-color: #ffffff;
}

.react-datepicker__navigation--previous {
    @include desktop {
        left: 70px;
    }
}

.react-datepicker__navigation--next {
    @include desktop {
        right: 70px;
    }
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
    background: none;
}
