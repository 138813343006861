/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --footer-content-height: 220px;
    --footer-copyright-height: 50px;
    --footer-nav-height: 48px;
    --footer-total-height: calc(env(safe-area-inset-bottom) + var(--footer-nav-height));

    --footer-background-color: var(--primary-blue-color);
    --footer-item-color: rgb(255, 255, 255);
}

.Footer {
    $column-count: 4;

    font-family: 'Roboto-Regular', sans-serif;

    &-CopyrightContentWrapper {
        background-color: var(--footer-background-color);
        color: var(--footer-item-color);
        padding: 10px 80px;


        @include mobile {
            text-align: center;
            padding-block-start: 20px;
            padding-block-end: 100px;
        }

        &::before {
            @mixin copyrightLine {
                display: block;
                width: 100%;
                content: '';
                margin-block-start: 30px;
                border-bottom: 1px solid rgb(221, 221, 221);
            }

            @include desktop {
                @include copyrightLine;
            }

            @include tablet {
                @include copyrightLine;
            }
        }
    }

    &-CopyrightContent {
        padding: 40px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: var(--footer-copyright-height);

        @include desktop {
            padding-block-end: 48px;
        }

        @include mobile {
            padding-block-start: 0;
            padding-block-end: 100px;
        }
    }

    &-Copyright {
        font-size: 12px;
        text-align: center;
        color: var(--footer-item-color);
        line-height: 16px;

        @include desktop {
            width: 100%;
            display: inline-block;
            padding: 40px 0;
        }


        a {
            color: inherit;
            font-weight: 400;
            font-size: 12px;

            &:hover {
                color: var(--primary-base-color);
            }
        }
    }

    &-Content {
        min-height: var(--footer-content-height);
        background-color: var(--footer-background-color);
        color: var(--footer-item-color);

        @include mobile {
            .Footer-Columns {
                border-bottom: 1px solid #727DA4;
                margin: 0px 20px 0px;
                padding: 30px 0 0;
            }
        }

        @include desktop {
            .Footer-Columns {
                padding: 80px 80px 0px;
            }
        }
    }

    &-Columns {
        display: flex;
        height: 100%;
        justify-content: space-between;

        svg path {
            fill: var(--footer-item-color);
        }

        @include mobile {
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 50px 16px 0 16px;
        }

        @include desktop {
            padding-inline: 32px;
            padding-block-start: 80px;
        }

        @include tablet {
            padding-inline: 30px;
        }
    }

    &-ColumnForMobile {
        @include desktop {
            display: none;
        }

        @include tablet {
            display: none;
        }
        
        @include mobile {
            width: 100%;
            text-align: start;
        }

        &:first-child {
            @include mobile {
                /* stylelint-disable-next-line declaration-no-important */
                margin-block-end: 30px !important;
            } 
        }

        &:not(:last-of-type) {
            @include mobile {
                margin-block-end: 10px;
            }

            @include desktop {
                padding-inline-end: 20px;
                max-width: calc(100% / #{ $column-count - 1 });
            }
        }

        &_type_companyInfo {
            @include mobile {
                display: none;
            }
        }

        &_type_socialForMobile {
            @include desktop {
                display: none;
            }

            @include tablet {
                display: none;
            }
        }

        &_type_socialForNotMobile {
            @include mobile {
                display: none;
            }
        }

        .ExpandableContent {
            border-top: 1px solid #727DA4;
            border-bottom: none;

            &-Button {
                &_isContentExpanded {
                    padding-block-end: 30px;
                }

                svg {
                    stroke: var(--primary-blue-color);
                    width: 25px;
                    height: 25px;
                }
            }

            &-Content {
                &_isContentExpanded {
                    padding-block-end: 20px;
                }
            }


            &-Heading {
                margin: 0;
                color: var(--footer-item-color);
                font-size: 14px;
                font-family: 'Roboto-Regular', sans-serif;
                font-weight: 500;
                text-transform: uppercase;
            }
        }
    }

    &-Column {
        @include mobile {
            display: none;
        }

        &:not(:last-of-type) {
            @include mobile {
                margin-block-end: 30px;
            }

            @include desktop {
                padding-inline-end: 20px;
                max-width: calc(100% / #{ $column-count - 1 });
            }
        }

        &:not(:first-of-type) {
            .Footer-ColumnItem {
                margin-block-end: 20px;
            }
        }

        &_type_companyInfo {
            @include mobile {
                display: none;
            }
        }

        &_type_socialForMobile {
            @include desktop {
                display: none;
            }

            @include tablet {
                display: none;
            }
        }

        &_type_socialForNotMobile {
            @include mobile {
                display: none;
            }

            .Footer-ColumnTitle {
                font-size: 28px;
            }
        }
    }

    &-ColumnTitle {
        margin: 0;
        margin-block-end: 15px;
        color: var(--footer-item-color);
        text-transform: uppercase;
        font-weight: 500;
        font-size: 20px;

        @include desktop {
            margin-block-end: 20px;
        }
    }

    &-ColumnContent {
        display: flex;
        flex-direction: column;
        color: var(--footer-item-color);

        &_direction_horizontal {
            flex-direction: row;

            @include mobile {
                justify-content: center;
            }
        }
    }

    &-ColumnItem {
        border-radius: 50%;
        color: inherit;
        margin-block-end: 12px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;

        @include mobile {
            font-size: 12px;
            margin-block-end: 30px;
        }

        &:last-of-type {
            margin: 0;
        }

        &:hover {
            text-decoration: underline;
            color: var(--footer-item-color);
        }

        &Image {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                // important required as default 100% width is passed as inline style
                // + you can't achieve same centering with just passing '16px' as inline style for the case
                /* stylelint-disable-next-line declaration-no-important */
                width: 16px !important;
                inset-block-start: 50%;
                inset-inline-start: 50%;
                transform: translate(-50%, -50%);

                &:dir(rtl) {
                    transform: translate(50%, -50%);
                }

                &:hover {
                    opacity: 0.6;
                }
            }
        }

        &_type_image {
            width: 44px;
            height: 44px;
            border: 1px solid var(--secondary-dark-color);

            &:not(:last-of-type) {
                margin-inline-end: 20px;
                margin-block-end: 0;
            }
        }

        &_notLink:hover {
            text-decoration-line: none;
        }

        &_display_forMobile {
            @include desktop {
                display: none;
            }

            @include tablet {
                display: none;
            }
        }

        &_display_forNotMobile {
            @include mobile {
                display: none;
            }
        }

        & a {
            color: var(--footer-item-color);
            font-weight: normal;
        }
    }

    &-CmsBlockWrapper {
        .CmsBlock-Wrapper {
            flex: 1;
        }

        .ContentWrapper .Footer-Columns {
            padding: 0;
        }

        .Footer-Column_isNewsletter {
            @include desktop {
                padding-inline-start: 100px;
            }
        }
    }

    .ExpandableContent-Button {
        @include mobile {
            padding-block-start: 0;
            padding-block-end: 0;
        }
    }

    .Footer-ColumnForMobile {
        .ExpandableContent-Content_isContentExpanded {
            @include mobile {
                padding-block-end: 0;
            }
        }
    }

    .Footer-ColumnItem {
        @include mobile {
            margin-block-start: 5px;
            margin-block-end: 5px;
        }
    }
}
