/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
/* stylelint-disable declaration-no-important */

.OrderReviewOverlay {
    overflow: inherit !important;
    position: fixed !important;
    width: 100%;
    //height: 45% !important;
    background: #ffffff;
    inset-block-start: 15% !important;
    z-index: 1;
    inset-block-end: 0;
    //border-radius: 30px 30px 0 0;
    line-height: 60px;
    text-align: center;
    font-size: 24px;
    animation: scaleDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    border-top: 10px solid var(--primary-blue-color);

    &_isVisible {
        animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }

    &-CloseBtn {
        background: white;
        width: 60px;
        height: 60px;
        display: inline-block;
        border-radius: 50%;
        position: absolute;
        inset-block-start: -30px;
        inset-inline-start: 50%;
        transform: translateX(-50%);
        box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.2);
    }

    &-Wrapper {
        margin: 55px 42px 60px;
        background: transparent;
        color: #ffffff;
        margin-block-end: 0px;
        text-align: center;

        @include mobile {
            margin: 0px;
        }

        h2 {
            font-size: 18px;
            margin: 5px;
        }

        h3 {
            color: #ffffff;
            font-family: "Roboto-Regular", sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
        }

        .Loader {
            background: transparent;
        }
    }

    &-List {
        li {
            height: 55px;
            line-height: 55px;
            text-align: start;
            margin: 0;
            display: flex;
            vertical-align: middle;
            align-items: center;

            &::before {
                content: "";
            }

            a, button {
                color: #ffffff;
                font-family: "Roboto-Regular", sans-serif;
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
            }
        }
    }

    &-Icon {
        line-height: 16px;
        height: 16px;
        padding-inline-end: 14px;

        &_type {
            &_chatWithUs,
            &_callUs {
                position: relative;
                inset-block-start: 3px;
            }
        }

        svg {
            fill: white;
            width: 16px;
            height: 16px;
        }
    }
}

.ReactModal__Content--after-open {
    @include mobile {
        width: 100% !important;
    }
}
// .react-simple-star-rating .empty-icons svg:nth-child(4) {
//     display: none;
// }

// .react-simple-star-rating .empty-icons svg:nth-child(5) {
//     display: none;
// }

// .react-simple-star-rating .filled-icons svg:nth-child(4) {
//     display: none;
// }

// .react-simple-star-rating .filled-icons svg:nth-child(5) {
//     display: none;
// }
