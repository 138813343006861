/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable property-no-unknown */
/* stylelint-disable declaration-no-important */
/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

:root {
    --navigation-tabs-height: calc(50px + env(safe-area-inset-bottom));
    --navigation-tabs-background: var(--primary-blue-color);
    --navigation-tabs-color: var(--secondary-dark-color);
}

.NavigationTabs {
    
    --header-background: var(--navigation-tabs-background);
    --navigation-tabs-background-expanded: var(--color-white);
    --header-icon-stroke-width: 2px;
    --footer-nav-height: 60px;

    position: fixed;
    inset-block-end: 0;
    inset-inline-start: 0;
    background-color: var(--header-background);
    margin: 10px 10px calc(10px + env(safe-area-inset-bottom)) 10px;
    transform: translateY(0);
    transition: transform 200ms cubic-bezier(.47, 0, .745, .715);
    transition: 200ms cubic-bezier(.47, 0, .745, .715);
    width: calc(100% - 20px);
    border-radius: 15px;
    box-shadow: 0 0 8px 0 #00000026;
    z-index: 1001;

  
    .Header-Button_type_minicart {
        width: 70px;
        height: 70px;
        position: relative;
        inset-block-start: 0px;
        border-radius: 50%;
        background-color: #0296E5;
        box-shadow: 0 -4px 8px 0 #00000033;
        display: flex;
        vertical-align: middle;
        align-items: center;
        transition: position 200ms ease-in-out;
    
        svg {
            width: 100%;
        }

        @include mobile {
            width: 50px;
            height: 50px;
        }
    }
    
    svg {
        fill: var(--color-white);

        &:hover {
            &:not(.CartIcon) {
                fill: var(--secondary-blue-color);
            }

            &.CartIcon {
                fill: var( --color-white);
            }
        }

        &.CartIcon {
            fill: var( --color-white);
        }
    }

    .hideOnScroll & {
        transform: translateY(150%);
        margin-block-end: 0;
    }

    &_expanded {
        margin: 18px 10px calc(18px + env(safe-area-inset-bottom));
        width: calc(100% - 20px);
        background-color: var(--navigation-tabs-background-expanded) !important;
        transition: 200ms cubic-bezier(.47, 0, .745, .715);
        border-radius: 15px;

        .Header {
            &-Button_type_minicart {
                inset-block-start: -25px;
            }

            &-MinicartItemCount {
                inset-block-start: 10px;
            }
        }

        svg {
            fill: var(--primary-blue-color);

            #WaterDrop,
            #Search,
            #User {
                path {
                    fill: var(--primary-blue-color); 
                }
            }
        }
    }

    &_blue {
        background-color: var(--primary-blue-color)!important;

        svg {
            fill: white;

            #WaterDrop,
            #Search,
            #User {
                path {
                    fill: white;
                }
            }
        }
    }

    @include desktop {
        display: none;
    }

    &_isHidden {
        display: none;

        @at-root .hiddenNavigationTabs {
            --navigation-tabs-height: env(safe-area-inset-bottom);
        }
    }

    &-Nav {
        height: var(--footer-nav-height);
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 12px;
        z-index: 20;
        max-width: var(--content-wrapper-width);
        margin: auto;

        @include mobile {
            padding: 0 14px;
        }
    }

    &-Button {
        flex-grow: 1;
        height: 100%;
        display: inherit;
        justify-content: center;
        align-items: center;

        svg {
            height: 24px;
            width: 24px;
        }
    }

    &-Icon {
        font-weight: bold;
    }

    @include mobile {
        width: 100%;
        border-radius: 0;
        padding-inline: 0;
        margin-inline: 0;
        padding-block-end: 0;
        padding-block-start: 0;
        margin-block-start: 0;
        margin-block-end: 0;
    }
}

@keyframes scaleUp {
    0% {
        transform: translateY(1000px);
        visibility: hidden;
    }


    100% {
        transform: translateY(0px);
        visibility: visible;
    }
}


@keyframes scaleDown {
    0% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(1000px);
    }
}

Footer.NavigationTabs {
    @include mobile {
        padding-block-end: 5px;
        padding-block-start: 5px;
    }
}
