/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ResetButton {
    &-Btn {
        color: #6C6F7A;
        font-size: 14px;
        line-height: 16px;
        text-decoration: underline;
        transition: 0.5s;

        &:hover {
            cursor: pointer;
            color: var(--primary-blue-color);
        }
    }
}
