/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
/* stylelint-disable declaration-no-important */

.PasswordChangePage {
    @include desktop() {
        padding-block-start: 70px;
        padding-block-end: 70px;
    }

    &-Title {
        color: var(--primary-blue-color);
    }

    .Field-TogglePasswordBtn {
        border: none;
        background: transparent;
        position: absolute;
        inset-block-end: 0;
        inset-inline-end: 0px;
        height: 46px;
        padding: 10px;
        width: 50px !important;
        min-width: unset !important;
        margin-block-end: 0;

        &:not([disabled]) {
            &:hover {
                @include desktop {
                    background-color: transparent;
                    border: none;
                    padding-inline-start: initial;
                    padding-inline-end: initial;
                }
            }
        }
    }
}
